<template>
  <div class="profile-wrapper">
    <ul class="nav nav-tabs nav-pills nav-fill " id="myTab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link"
          id="user-profil-tab"
          data-toggle="tab"
          href="#user-profile"
          role="tab"
          aria-controls="user-profile"
          aria-selected="true"
        >
          <img src="../../assets/images/white-user.png" alt="" />
          User Details
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link active"
          id="company-profile-tab"
          data-toggle="tab"
          href="#company-profile"
          role="tab"
          aria-controls="company-profile"
          aria-selected="false"
        >
          <img src="../../assets/images/company-profile-icon.png" alt="" />
          My Company Details</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CompanyProfileTop",
};
</script>

<style></style>
