<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper">
        <CompanyProfileTop />
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade "
            id="user-profile"
            role="tabpanel"
            aria-labelledby="user-profile-tab"
          >
            <UserProfile />
          </div>
          <div
            class="tab-pane fade show active"
            id="company-profile"
            role="tabpanel"
            aria-labelledby="company-profile-tab"
          >
            <CompanyProfile />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "../../components/Enterprise/leftmenu/LeftMenu.vue";
import Navbar from "../../components/Enterprise/header/Navbar.vue";
import CompanyProfileTop from "../../components/Enterprise/companyprofile/CompanyProfileTop.vue";
import UserProfile from "../../components/Enterprise/profile/UserProfile.vue";
import CompanyProfile from "../../components/Enterprise/companyprofile/CompanyProfile.vue";
import {  mapActions } from "vuex";
export default {
  name: "EnterpriseCompanyProfilePage",
  components: {
    LeftMenu,
    Navbar,
    CompanyProfileTop,
    UserProfile,
    CompanyProfile,
  },
  methods: {
    ...mapActions("auth", ["getUserData"]),
  },
  mounted(){
  }
 
};
</script>

<style></style>
